<template>
  <div>
    <b-row v-show="!imageLoading">
      <b-col>
        <b-card>
            <b-card-text>
              <form @submit.prevent="saveImage">
                <b-form-group
                  label="Product title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="newTitle"
                  />
                </b-form-group>

                <label for="tags-pills">Tags</label>
                <b-form-tags
                  v-model="image.tags"
                  input-id="tags-pills"
                  tag-variant="primary"
                  tag-pills
                  size="lg"
                  separator=","
                  placeholder=""
                  class="mb-2"
                />

                <b-form-group
                  label="Album name"
                  label-for="album-name"
                  class="mb-3"
                >
                  <b-form-input
                    id="album-name"
                    placeholder=""
                    size="lg"
                    v-model="image.album"
                  />
                </b-form-group>

                <b-button
                  type="submit"
                  variant="success"
                >
                  Save
                </b-button>
              </form>
            </b-card-text>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <img :src="image.thumbnail"/>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center mb-3">
      <b-spinner variant="primary" label="Loading" v-show="imageLoading"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BFormGroup, BFormInput, BDropdown, BDropdownItem, BSpinner, BRow, BCol, BFormTags, BButton, } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/api'

import '@/@core/scss/vue/libs/vue-good-table.scss'

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardText,
    BFormGroup, 
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BRow,
    BCol,
    BFormTags,
    BButton,
  },

  data() {
    return {
      image: {},
      imageLoading: true,
    
      newTitle: "",
    }
  },

  mounted() {
    this.getImage()
  },

  methods: {
    getImage() {
      api.images.get(this.$route.params.id).then(response => {
        this.image = response.data
        this.newTitle = this.stringToHTML(response.data.meta.title)
        this.imageLoading = false
      })
    },

    stringToHTML(str) {
      var parser = new DOMParser()
      var doc = parser.parseFromString(str, 'text/html')

      return doc.body.innerHTML
    },

    saveImage() {
      this.imageLoading = true

      let newImage = this.image
      newImage.meta = {}
      newImage.meta.title = this.newTitle

      api.images.update(this.$route.params.id, newImage).then(response => {
        if (response.status === 200) {
          this.$router.push('/products/manage').then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Product updated`,
                icon: 'CheckIcon',
                variant: 'success',
                text: '',
              },
            })
          });
        } else {
          throw new Error();
        }
      }).catch(error => {
        this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error occured`,
                icon: 'XIcon',
                variant: 'danger',
                text: 'Please try again later or contact support if the problem remains',
              },
            })

          this.imageLoading = false
      })
    }
  }
}
</script>

<style>
  .image-preview {
    max-width: 80px;
  }
</style>
